.main_img {
  position: relative;
  height: auto;
  width: auto;
}

.btn_join {
  background-color: black;
  border: 2px solid white;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nav {
  cursor: pointer;
}
